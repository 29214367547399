<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

import "prismjs";
import "prismjs/themes/prism.css";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Swal from "sweetalert2";
// import store from '../state/store'
import store from "../../../state/store";	
let idToken = store.state.auth.idToken;
import Cookies from 'js-cookie';

export default {
  data() {
    return {
      selectedCountry: "india",
      assignedUser: null,
      event: null,
    };
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  computed: {
    ...mapGetters({
      getOrders: "order/getOrders",
      userData: "auth/user",
      getUserLists: "users/getUserLists",
    }),
    isAdmin() {
      return this.userData && this.userData.role_id == 1 ? true : false
    },
    isUser() {
      return this.userData && this.userData.role_id == 3 ? true : false
    },
    teamUsers() {
      var teamUsersData = this.getUserLists.filter((user) => user.role_id == 2 || user.role_id == 4 || user.role_id == 5)
      var teamData = [];
      teamUsersData.map((user) => {
        var payload = {
          value: user.id,
          label: user.name,
        };
        teamData.push(payload);
      });
      return teamData;
    },
    filteredOrders() {
      return this.assignedUser ? this.getOrders.filter((order) => order.assigned_user == this.assignedUser) : this.getOrders
    }
  },
  mounted() {

    const mobileAppCookie = Cookies.get('mobileapp');
        console.log('mobileappcookie is ',mobileAppCookie);

        if (mobileAppCookie === 'true') {
      document.getElementById('page-topbar').style.display = 'none';
      
    }



    this.setOrders();
    this.fetchUserLists();

    this.getOrderEvent(this.userData.id)
      .then(({data}) => {
        this.event = data.data.events
      })
  },
  methods: {
    ...mapActions({
      setOrders: "order/setOrders",
      deleteOrder: "order/deleteOrder",
      fetchUserLists: "users/fetchUserLists",
      getOrderEvent: "order/getOrderEvent",
    }),
    getDate(date) {
      return moment(date).format("MM/DD/YY");
    },
    deleteOrderData(id) {
      Swal.fire({
        title: this.$t('t-are-you-sure'),
        text: this.$t('t-not-revert'),
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#f46a6a",
        cancelButtonText: this.$t('t-cancel'),
        confirmButtonColor: "#34c38f",
        confirmButtonText: this.$t('t-yes-delete-it'),
      }).then((result) => {
        if (result.value) {
          this.deleteOrder(id)
            .then((response) => {
              console.log(response);
            })
            .catch(() => {
              Swal.fire("Oops...", "Something went wrong", "error");
            });
        }
      });
    },
    editOrderData(orderId) {
      if (this.userData && this.userData.role_id != 3) {
        this.$router.push({
          name: 'EditOrder',
          params: {
            id: btoa(orderId)
          },
        });
      } else {
        this.$router.push({
          name: 'UserOrderEdit',
          params: {
            id: btoa(orderId)
          },
        });
      }
    },
    addOrderRedirect() {
      if (this.userData && this.userData.role_id != 3 ) {
        this.$router.push({
          name: 'AddOrder'
        });
      } else {
        this.$router.push({
          name: 'UserOrderAdd'
        });
      }
    },

    downloadOrders()  {
console.log('userdata is', this.userData)
      

      var myHeaders = new Headers();
myHeaders.append("Authorization", "Bearer " + idToken);

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch("https://e-triq.com/api/triq-backend-laravel/public/api/order/download-orders", requestOptions)
  .then(response => response.blob())
  .then(blob => {
      var url = window.URL.createObjectURL(blob); // create an object URL for the blob
      var a = document.createElement('a'); // create a new anchor element
      a.href = url; // set the href of the anchor to the object URL
      a.download = 'orders.csv'; // specify the filename for the download
      document.body.appendChild(a); // append the anchor to the body
      a.click(); // simulate a click on the anchor
      document.body.removeChild(a); // remove the anchor from the body
    })
  .catch(error => console.log('error', error));

      alert('download orders');
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('t-orders')" />
    <div class="row">
    <div class="colm-md-12">
      
      <button @click="downloadOrders" class="btn btn-success mb-2">Download Orders</button>
    </div>
      <div class="col-xl-12">
        <div class="filter">
          <div class="row">
            <div class="col-3"  v-if="userData.role_id == 1">
              <label>Country</label>
              <Multiselect v-model="selectedCountry" :close-on-select="true" :searchable="true" :create-option="true"
                :options="[
                  { value: 'india', label: 'India' },
                  { value: 'Uae', label: 'UAE' },
                ]" />
            </div>
            <div class="col-3" v-if="userData.role_id == 1">
              <label>Assigned User</label>
              <Multiselect v-model="assignedUser" :close-on-select="true" :searchable="true" :create-option="true"
                :options="teamUsers" />
            </div>
            <!-- <div class="col-9 d-flex justify-content-end">
              <b-button
                variant="primary"
                class="btn btn-primary waves-effect waves-light"
                >Filter</b-button
              >
            </div> -->
          </div>
        </div>

        <div class="card mt-4">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">{{ $t("t-orders") }}</h4>
            <div class="flex-shrink-0">
              <!-- <router-link
                to="/admin/order/add"
                class="btn btn-primary waves-effect waves-light"
              >
                Add Order
              </router-link> -->
              <button  v-if="(userData && userData.role_id == 1)" class="btn btn-primary waves-effect waves-light" @click="addOrderRedirect">{{ $t('order.add-order')
              }}</button>
            </div>
          </div>
          <!-- end card header -->
          <div class="card-body">

            <div class="table-responsive table-card">
              <table class="table align-middle table-nowrap mb-0">
                <thead class="table-light">
                  <tr>
                    <!-- <th scope="col">{{ $t('order.id') }}</th> -->
                    <th scope="col">Order ID</th>
                    <th scope="col">{{ $t('order.date') }}</th>
                    <th scope="col" v-if="(userData && userData.role_id != 3)">{{ $t('order.user') }}</th>
                    <th scope="col">{{ $t('order.service') }}</th>
                    <th scope="col">{{ $t('order.order-status') }}</th>
                    <th scope="col">{{ $t('order.payment-status') }}</th>
                    <th scope="col">{{ $t('order.assigned-user') }}</th>
                    <th scope="col">{{ $t('order.action') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(order, index) in filteredOrders" :key="index">

                    <td>#{{ order.id }}</td>
                    <td>{{ getDate(order.created_at) }}</td>
                    <td v-if="(userData && userData.role_id != 3)">{{ order.user && order.user.name ? order.user.name :
                      '-' }}</td>
                    <td>{{ order.service && order.service.title ? order.service.title : '-' }}</td>
                    <td class="text-capitalize">{{ order.service_status }}</td>
                    <td class="text-capitalize">{{ order.payment_status }}</td>
                    <td>{{ order.assigned_to && order.assigned_to.name ? order.assigned_to.name : userData.name }}</td>
                    <td>
                      <div class="hstack gap-3 flex-wrap">
                        <a class="link-success fs-15" @click="editOrderData(order.id)"><i class="ri-edit-2-line"></i></a>
                   
                   
                   
                        <a v-if="order && order.user && order.user.name
                        && order.service.id == 2 && !isUser"
                          :href="`/client-call/${order.user.name.replace(/\s+/g, '-')}~${order.user_id}`"><i
                            class="ri-phone-fill" aria-hidden="true"></i></a>

                            <a v-if="order && order.user && order.user.name
                        && order.service.id == 2 && isUser"
                          :href="`/client-call/${order.assigned_to.name.replace(/\s+/g, '-')}~${order.assigned_to.id}`"><i
                            class="ri-phone-fill" aria-hidden="true"></i></a>



                        <a v-if="isAdmin" href="javascript:void(0);" @click="deleteOrderData(order.id)"
                          class="link-danger fs-15"><i class="ri-delete-bin-line"></i></a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      <!-- end col -->
    </div>

    <!--end row-->
  </Layout>
</template>
